
import { computed, defineComponent, reactive, ref } from "vue";
import BaseCard from "@/app/core/components/cards/BaseCard.vue";
import BaseInput from "@/app/core/components/forms/BaseInput.vue";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import BaseButton from "@/app/core/components/buttons/BaseButton.vue";
import BaseIcon from "@/app/core/components/BaseIcon.vue";
import { useProfileModule } from "@/store/modules/ProfileModule";
import { container } from "tsyringe";
import { UserService } from "@/app/core/services/UserService";
import { UserResponseContract } from "@/app/core/interfaces/services/user/UserResponseContract";
import { UserUpdateRequestContract } from "@/app/core/interfaces/services/user/UserUpdateRequestContract";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "ContactInfo",
  components: { BaseIcon, BaseButton, BaseInput, BaseCard },
  async setup() {
    const router = useRouter();
    const profileCtx = useProfileModule();
    const userService: UserService = container.resolve(UserService);
    const toast = useToast();

    if (!profileCtx.state.user) {
      await profileCtx.actions.fetchUser();
    }

    const handleFileUpload = (event: any) => {
      let requestedFile = event.target.files[0];
      const formData = new FormData();
      formData.append("file", requestedFile);
      toast.info("Uploading your avatar");
      userService.uploadAvatar(formData).then(() => {
        toast.success("Successfully uploaded avatar");
        profileCtx.actions.fetchUser();
      });
    };

    const removeAvatar = () => {
      toast.info("Deleting your avatar");
      userService.removeAvatar().then(() => {
        toast.success("Successfully deleted avatar");
        profileCtx.actions.fetchUser();
      });
    };

    let updateUserInfo = reactive<UserUpdateRequestContract>({
      contactName: profileCtx.getters.getUser?.contactName,
      email: profileCtx.getters.getUser?.email,
      faxNumber: profileCtx.getters.getUser?.faxNumber,
      phoneNumber: profileCtx.getters.getUser?.phoneNumber,
      localPhone: profileCtx.getters.getUser?.localPhone,
    });

    const rules = {
      contactName: {
        required,
      },
      email: {
        required,
        email,
      },
      faxNumber: {
        required,
      },
      phoneNumber: {},
      localPhone: {},
    };

    const v$ = useVuelidate(rules, updateUserInfo as any);

    const submit = () => {
      v$.value.$touch();
      if (!v$.value.$invalid) {
        userService
          .update<UserUpdateRequestContract, UserResponseContract>(updateUserInfo)
          .then((res) => {
            profileCtx.mutations.setUser(res);
            router.push({ name: "Profile" });
          });
      }
    };

    return {
      updateUserInfo,
      handleFileUpload,
      v$,
      submit,
      removeAvatar,
      avatarHref: computed(() => {
        return profileCtx.getters.getAvatarHref;
      }),
    };
  },
});
